<template>
  <div class="operate-component">
    <span @click="operate(1)" class="step">上移</span>
    <span @click="operate(-1)" class="step">下移</span>
    <span @click="operate(100)" class="group">置顶</span>
    <span @click="operate(-100)" class="group">置底</span>
    <span class="delete-cpt" @click="operate(0)">删除</span>
  </div>
</template>

<script>
export default {
  name: 'operate-component',
  methods: {
    operate(type) {
      if (type === 0) {
        this.$confirm('确定删除该组件吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$emit('operatetype', type)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else {
        this.$emit('operatetype', type)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.operate-component {
  background: @color-background;
  text-align: center;
  box-shadow: 0px 4px 10px 0px rgba(146, 146, 146, 0.38);
  width: 136px;
  position: relative;
  .radius(6px);
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    width: 56px;
    height: 72px;
    line-height: 56px;
    background: #f6f6f6;
    vertical-align: center;
    margin: 5px;
    font-size: 14px;
    .radius(10px);

    &:last-child {
      &:hover {
        cursor: pointer;
        background: @color-primary-select;
        color: @color-background;
      }
    }
    &:hover {
      cursor: pointer;
      background: #1975ff;
      color: @color-background;
    }
  }
  .step {
    width: 56px;
    height: 72px;
    line-height: 72px;
    background: #f6f6f6;
    border-radius: 10px;
    color: #333333;
  }
  .group {
    width: 56px;
    height: 30px;
    line-height: 30px;
    background: #f6f6f6;
    border-radius: 10px;
  }
  .delete-cpt {
    width: 120px;
    height: 30px;
    line-height: 30px;
    background: #f6f6f6;
    border-radius: 10px;
    font-size: 14px;
    color: #e7141a;
  }
}
</style>
